import { useState } from 'react';
import { useProducts, SelectedProducts } from './hooks/useProducts';
import { ProductList } from './components/ProductList';
import { CustomerForm } from './components/CustomerForm';
import { TotalBar } from './components/TotalBar';
import { PreviewModal } from './components/PreviewModal';
import { Customer, Product } from './types/notion';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { QuotePDF } from './components/QuotePDF';
import { TotalCosts } from './components/TotalCosts';
import { AuthProvider } from './contexts/AuthContext';
import { Login } from './components/Login';
import { useAuth } from './contexts/AuthContext';

// Typdefinitionen
type ProductNotes = Record<string, string>;
type CustomPrices = Record<string, number>;

interface ProductWithCustomData extends Product {
  note?: string;
  customPrice?: number;
}

function AuthenticatedApp() {
  const { currentUser, signOut } = useAuth();
  const { 
    products, 
    loading, 
    error, 
    selectedProducts, 
    toggleProduct, 
    getTotalPrice,
    addCustomProduct,
    removeCustomProduct,
    updateProductPrice,
    updateProductNote,
    customProducts: customProductsData // Renamed to customProductsData
  } = useProducts();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [productNotes, setProductNotes] = useState<ProductNotes>({});
  const [productPrices, setProductPrices] = useState<CustomPrices>({});
  const [customer, setCustomer] = useState<Customer>({
    firma: '',
    ansprechpartner: '',
    strasse: '',
    plz: '',
    ort: '',
    email: '',
    telefon: ''
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="w-6 h-6 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 p-4">
        {error}
      </div>
    );
  }

  const handleNoteChange = (productId: string, note: string): void => {
    setProductNotes((prev: ProductNotes): ProductNotes => ({
      ...prev,
      [productId]: note
    }));
  };

  const handleProductPriceChange = (productId: string, price: number): void => {
    setProductPrices((prev: CustomPrices): CustomPrices => ({
      ...prev,
      [productId]: price
    }));
    updateProductPrice(productId, price);
  };

  // Füge Notizen und angepasste Preise zu den ausgewählten Produkten hinzu
  const selectedProductsWithNotesAndPrices: ProductWithCustomData[] = products
    .filter((p: Product) => selectedProducts[p.id])
    .map((p: Product) => {
      const note = productNotes[p.id] || '';  // Standardwert wenn keine Notiz existiert
      const customPrice = productPrices[p.id] || p.price;  // Standardpreis wenn kein angepasster Preis existiert
      return {
        ...p,
        note,
        customPrice
      };
    });

  const totalPrice = selectedProductsWithNotesAndPrices.reduce(
    (sum, product) => sum + (product.customPrice ?? product.price),
    0
  );

  const handlePreview = (): void => {
    setIsPreviewOpen(true);
  };

  const handleExportPDF = async (): Promise<void> => {
    const currentDate = new Date().toLocaleDateString('de-DE', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    const fileName = `Angebot_${customer.firma}_${currentDate}.pdf`;

    // Alle ausgewählten Produkte sammeln (products enthält bereits customProducts)
    const allSelectedProducts = products
      .filter(product => selectedProducts[product.id]?.selected)
      .map(product => ({
        ...product,
        customPrice: selectedProducts[product.id]?.price || product.price,
        note: productNotes[product.id]
      }));

    console.log('PDF Generation - products:', products);
    console.log('PDF Generation - customProductsData:', customProductsData);
    console.log('PDF Generation - selectedProducts:', selectedProducts);
    console.log('PDF Generation - allSelectedProducts:', allSelectedProducts);

    const QuoteDoc = (
      <QuotePDF
        customer={customer}
        selectedProducts={allSelectedProducts}
        totalPrice={getTotalPrice(productPrices)}
        date={currentDate}
      />
    );

    try {
      const { pdf } = await import('@react-pdf/renderer');
      const blob = await pdf(QuoteDoc).toBlob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Fehler beim Generieren des PDFs. Bitte versuchen Sie es erneut.');
    }
  };

  const handleSaveQuote = (): void => {
    // TODO: Implement save quote
    console.log('Save quote clicked');
  };

  const handleCustomerChange = (newCustomer: Customer): void => {
    setCustomer(newCustomer);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <h1 className="text-xl font-semibold">Angebote App</h1>
            </div>
            <div className="flex items-center">
              <span className="mr-4">{currentUser?.email}</span>
              <button
                onClick={() => signOut()}
                className="bg-indigo-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-indigo-700"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 py-8 max-w-5xl">
        <h1 className="text-3xl font-bold mb-8 text-gray-900">Angebots-App</h1>
        
        <div className="space-y-8">
          <CustomerForm customer={customer} onCustomerChange={handleCustomerChange} />
          
          <ProductList
            products={products}
            selectedProducts={selectedProducts}
            onToggleProduct={toggleProduct}
            onNoteChange={handleNoteChange}
            onPriceChange={handleProductPriceChange}
            addCustomProduct={addCustomProduct}
            removeCustomProduct={removeCustomProduct}
          />

          <TotalCosts 
            products={products}
            selectedProducts={selectedProducts} 
            productPrices={productPrices} 
          />
        </div>
      </div>

      {/* Schwebende Leiste */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t shadow-lg">
        <div className="container mx-auto px-4 py-4 max-w-5xl">
          <div className="flex justify-between items-center">
            <div className="text-lg font-semibold text-gray-900">
              Gesamt: {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(getTotalPrice(productPrices))}
            </div>
            <div className="space-x-4">
              <button
                onClick={handlePreview}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
              >
                Vorschau
              </button>
              <button
                onClick={handleExportPDF}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
              >
                PDF exportieren
              </button>
            </div>
          </div>
        </div>
      </div>

      <PreviewModal
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        customer={customer}
        products={products}
        customProducts={customProductsData}
        selectedProducts={selectedProducts}
        totalPrice={getTotalPrice(productPrices)}
        productNotes={productNotes}
        productPrices={productPrices}
      />
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

function AppContent() {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return <Login />;
  }

  return <AuthenticatedApp />;
}

export default App;
