import React from 'react';
import Modal from 'react-modal';
import { PDFViewer } from '@react-pdf/renderer';
import { QuotePDF } from './QuotePDF';
import { Customer, Product, SelectedProducts } from '../types/notion';
import { X } from 'lucide-react';

interface PreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  customer: Customer;
  products: Product[];
  customProducts: Product[];
  selectedProducts: SelectedProducts;
  totalPrice: number;
  productNotes: Record<string, string>;
  productPrices: Record<string, number>;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    height: '90vh',
    padding: '20px',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: '#fff',
    boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 1000,
  },
};

Modal.setAppElement('#root');

export const PreviewModal: React.FC<PreviewModalProps> = ({
  isOpen,
  onClose,
  customer,
  products,
  customProducts,
  selectedProducts,
  totalPrice,
  productNotes,
  productPrices,
}) => {
  const currentDate = new Date().toLocaleDateString('de-DE', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  // Alle ausgewählten Produkte sammeln (products enthält bereits customProducts)
  const allSelectedProducts = products
    .filter(product => {
      console.log('Checking product:', product);
      console.log('Selected status:', selectedProducts[product.id]);
      return selectedProducts[product.id]?.selected;
    })
    .map(product => {
      const mappedProduct = {
        ...product,
        customPrice: selectedProducts[product.id]?.price || product.price,
        note: productNotes[product.id]
      };
      console.log('Mapped product:', mappedProduct);
      return mappedProduct;
    });

  console.log('Preview - products:', products);
  console.log('Preview - customProducts:', customProducts);
  console.log('Preview - selectedProducts:', selectedProducts);
  console.log('Preview - allSelectedProducts:', allSelectedProducts);

  console.log('Selected products for preview:', allSelectedProducts);

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    }).format(amount);
  };

  const totals = allSelectedProducts.reduce(
    (acc, product) => {
      const price = product.customPrice ?? product.price;
      acc[product.period] = (acc[product.period] || 0) + price;
      return acc;
    },
    {} as Record<string, number>
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Angebot Vorschau"
    >
      <div className="h-full flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Vorschau</h2>
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        {/* Gesamtkosten */}
        <div className="mb-4 p-4 bg-gray-50 rounded-lg">
          <h3 className="text-lg font-semibold text-gray-900 mb-4">Gesamtkosten</h3>
          <div className="grid grid-cols-3 gap-4">
            {totals.einmalig > 0 && (
              <div className="flex flex-col">
                <span className="text-gray-600">Einmalig</span>
                <span className="font-semibold">{formatCurrency(totals.einmalig)}</span>
              </div>
            )}
            {totals.monatlich > 0 && (
              <div className="flex flex-col">
                <span className="text-gray-600">Monatlich</span>
                <span className="font-semibold">{formatCurrency(totals.monatlich)}</span>
              </div>
            )}
            {totals.quartalsweise > 0 && (
              <div className="flex flex-col">
                <span className="text-gray-600">Quartalsweise</span>
                <span className="font-semibold">{formatCurrency(totals.quartalsweise)}</span>
              </div>
            )}
          </div>
        </div>
        
        <div className="flex-1 bg-gray-50 rounded-lg overflow-hidden">
          <PDFViewer 
            style={{ 
              width: '100%', 
              height: '100%', 
              border: 'none',
              backgroundColor: '#f9fafb'
            }}
          >
            <QuotePDF
              customer={customer}
              selectedProducts={allSelectedProducts}
              totalPrice={totalPrice}
              date={currentDate}
            />
          </PDFViewer>
        </div>
      </div>
    </Modal>
  );
};
