import React from 'react';
import { Customer } from '../types/notion';

interface CustomerFormProps {
  className?: string;
  customer: Customer;
  onCustomerChange: (customer: Customer) => void;
}

export const CustomerForm: React.FC<CustomerFormProps> = ({
  className = '',
  customer,
  onCustomerChange,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    onCustomerChange({
      ...customer,
      [id]: value,
    });
  };

  return (
    <div className={`bg-white rounded-lg p-6 shadow-sm ${className}`}>
      <h2 className="text-xl font-semibold mb-6 text-gray-900">Kunde</h2>
      <div className="space-y-6">
        <div className="grid grid-cols-2 gap-8">
          <div>
            <label htmlFor="firma" className="block text-sm font-normal text-gray-600 mb-2">
              Firma
            </label>
            <input
              type="text"
              id="firma"
              value={customer.firma}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-200 rounded-lg bg-white text-gray-900"
              placeholder="Firmenname"
            />
          </div>
          <div>
            <label htmlFor="ansprechpartner" className="block text-sm font-normal text-gray-600 mb-2">
              Ansprechpartner
            </label>
            <input
              type="text"
              id="ansprechpartner"
              value={customer.ansprechpartner}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-200 rounded-lg bg-white text-gray-900"
              placeholder="Name des Ansprechpartners"
            />
          </div>
        </div>

        <div className="grid grid-cols-12 gap-8">
          <div className="col-span-6">
            <label htmlFor="strasse" className="block text-sm font-normal text-gray-600 mb-2">
              Straße
            </label>
            <input
              type="text"
              id="strasse"
              value={customer.strasse}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-200 rounded-lg bg-white text-gray-900"
              placeholder="Straße und Hausnummer"
            />
          </div>
          <div className="col-span-3">
            <label htmlFor="plz" className="block text-sm font-normal text-gray-600 mb-2">
              PLZ
            </label>
            <input
              type="text"
              id="plz"
              value={customer.plz}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-200 rounded-lg bg-white text-gray-900"
              placeholder="PLZ"
            />
          </div>
          <div className="col-span-3">
            <label htmlFor="ort" className="block text-sm font-normal text-gray-600 mb-2">
              Ort
            </label>
            <input
              type="text"
              id="ort"
              value={customer.ort}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-200 rounded-lg bg-white text-gray-900"
              placeholder="Ort"
            />
          </div>
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-normal text-gray-600 mb-2">
            E-Mail
          </label>
          <input
            type="email"
            id="email"
            value={customer.email}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-200 rounded-lg bg-white text-gray-900"
            placeholder="E-Mail-Adresse"
          />
        </div>
      </div>
    </div>
  );
};
