import { useState } from 'react';
import { Product } from '../types/notion';

interface CustomProductFormProps {
  onAdd: (product: Product) => void;
}

export function CustomProductForm({ onAdd }: CustomProductFormProps) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [period, setPeriod] = useState('einmalig');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Stelle sicher, dass der Preis eine gültige Zahl ist
    const numericPrice = parseFloat(price);
    if (isNaN(numericPrice)) {
      alert('Bitte geben Sie einen gültigen Preis ein');
      return;
    }

    const newProduct: Product = {
      id: `custom-${Date.now()}`, // Generiere eine eindeutige ID
      name,
      description,
      price: numericPrice,
      category: 'Individuell',
      period,
      hint: '',
    };

    console.log('Neues Produkt erstellt:', newProduct);
    onAdd(newProduct);
    
    // Form zurücksetzen
    setName('');
    setDescription('');
    setPrice('');
    setPeriod('einmalig');
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 bg-white p-4 rounded-lg shadow">
      <h3 className="text-lg font-semibold mb-4">Individuelles Produkt erstellen</h3>
      
      <div>
        <label className="block text-sm font-medium text-gray-700">Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 bg-white text-gray-900"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Beschreibung</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 bg-white text-gray-900"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Preis (€)</label>
        <input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          required
          min="0"
          step="0.01"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 bg-white text-gray-900"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Periode</label>
        <select
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 bg-white text-gray-900"
        >
          <option value="einmalig">Einmalig</option>
          <option value="monatlich">Monatlich</option>
          <option value="quartalsweise">Quartalsweise</option>
          <option value="jährlich">Jährlich</option>
        </select>
      </div>

      <button
        type="submit"
        className="w-full bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Produkt hinzufügen
      </button>
    </form>
  );
}
