import React, { useState } from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon, HelpCircle } from 'lucide-react';
import { Product } from '../types/notion';
import * as Tooltip from '@radix-ui/react-tooltip';
import { SelectedProducts } from '../hooks/useProducts';
import { CustomProductForm } from './CustomProductForm';

interface ProductListProps {
  products: Product[];
  selectedProducts: SelectedProducts;
  onToggleProduct: (productId: string) => void;
  onNoteChange: (productId: string, note: string) => void;
  onPriceChange: (productId: string, price: number) => void;
  addCustomProduct: (product: Product) => void;
  removeCustomProduct: (productId: string) => void;
  className?: string;
}

// Definiere die Reihenfolge der Kategorien mit Farben
const categories = [
  { name: 'Plan', bgColor: 'bg-blue-50' },
  { name: 'Paid Ads', bgColor: 'bg-purple-50' },
  { name: 'Content', bgColor: 'bg-green-50' },
  { name: 'Newsletter', bgColor: 'bg-yellow-50' },
  { name: 'Social Media', bgColor: 'bg-pink-50' },
  { name: 'Individuell', bgColor: 'bg-orange-50' },
  { name: 'Sonstiges', bgColor: 'bg-gray-50' },
];

export const ProductList: React.FC<ProductListProps> = ({
  products,
  selectedProducts,
  onToggleProduct,
  onNoteChange,
  onPriceChange,
  addCustomProduct,
  removeCustomProduct,
  className = '',
}) => {
  const [showCustomForm, setShowCustomForm] = useState(false);

  // Gruppiere Produkte nach Kategorie
  const productsByCategory = products.reduce((acc, product) => {
    const category = product.category || 'Sonstiges';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(product);
    return acc;
  }, {} as Record<string, Product[]>);

  console.log('Alle Produkte:', products);
  console.log('Produkte nach Kategorie:', productsByCategory);

  const handlePriceChange = (productId: string, value: string) => {
    const price = value === '' ? 0 : parseFloat(value);
    if (!isNaN(price)) {
      onPriceChange(productId, price);
    }
  };

  const productItemStyles = {
    container: {
      borderRadius: '8px',
      backgroundColor: 'white',
      padding: '16px',
      marginBottom: '12px',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: '8px',
    },
    leftSection: {
      flex: 1,
    },
    rightSection: {
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'flex-end',
      gap: '8px',
    },
    title: {
      fontSize: '16px',
      fontWeight: 'bold',
      marginBottom: '4px',
    },
    description: {
      fontSize: '14px',
      color: '#666',
      marginBottom: '8px',
    },
    priceSection: {
      textAlign: 'left',
    },
    price: {
      fontSize: '16px',
      fontWeight: 'bold',
      marginBottom: '4px',
    },
    periodBadge: {
      backgroundColor: '#e8f0fe',
      color: '#1a73e8',
      padding: '4px 8px',
      borderRadius: '4px',
      fontSize: '12px',
    },
    inputsContainer: {
      marginTop: '12px',
    },
    inputGroup: {
      marginBottom: '8px',
    },
    label: {
      display: 'block',
      marginBottom: '4px',
      fontSize: '14px',
      color: '#666',
    },
    input: {
      width: '100%',
      padding: '8px',
      border: '1px solid #ddd',
      borderRadius: '4px',
      fontSize: '14px',
    },
    checkbox: {
      marginRight: '8px',
    },
  };

  const handleAddCustomProduct = (product: Product) => {
    console.log('ProductList: Füge Custom Product hinzu:', product);
    addCustomProduct(product);
    setShowCustomForm(false);
  };

  return (
    <Tooltip.Provider delayDuration={300}>
      <div className={`space-y-6 ${className}`}>
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">Produkte</h2>
          <button
            onClick={() => setShowCustomForm(!showCustomForm)}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            {showCustomForm ? 'Formular ausblenden' : 'Individuelles Produkt erstellen'}
          </button>
        </div>

        {showCustomForm && (
          <CustomProductForm onAdd={handleAddCustomProduct} />
        )}

        {categories.map(({ name: category, bgColor }) => {
          const categoryProducts = productsByCategory[category];
          if (!categoryProducts?.length) return null;

          return (
            <div key={category} className={`rounded-lg p-6 shadow-sm ${bgColor}`}>
              <h2 className="text-xl font-semibold mb-4 text-gray-900">{category}</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {categoryProducts.map((product) => (
                  <div
                    key={product.id}
                    className={`relative border rounded-lg p-4 ${
                      selectedProducts[product.id]?.selected ? 'border-blue-600 bg-blue-50' : 'border-gray-200 bg-white'
                    }`}
                  >
                    <div className="flex flex-col gap-4">
                      <div className="flex items-start justify-between">
                        <h3 className="text-gray-900 font-medium">
                          {product.name}
                          {product.category === 'Individuell' && (
                            <button
                              onClick={() => removeCustomProduct(product.id)}
                              className="ml-2 text-gray-400 hover:text-gray-600"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M18 6L6 18M6 6l12 12"></path>
                              </svg>
                            </button>
                          )}
                        </h3>
                        <Checkbox.Root
                          className="flex h-5 w-5 items-center justify-center rounded border border-gray-300 bg-white"
                          checked={selectedProducts[product.id]?.selected}
                          onCheckedChange={() => onToggleProduct(product.id)}
                        >
                          <Checkbox.Indicator>
                            <CheckIcon className="h-4 w-4 text-gray-900" />
                          </Checkbox.Indicator>
                        </Checkbox.Root>
                      </div>

                      {product.description && (
                        <p className="text-gray-600 text-sm">{product.description}</p>
                      )}

                      <textarea
                        className="w-full p-2 border rounded-md bg-white text-gray-900"
                        placeholder="Anmerkungen..."
                        onChange={(e) => onNoteChange(product.id, e.target.value)}
                        rows={3}
                      />

                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <input
                            type="number"
                            value={(selectedProducts[product.id]?.price ?? product.price).toFixed(2)}
                            onChange={(e) => handlePriceChange(product.id, e.target.value)}
                            className="w-24 px-2 py-1 border rounded bg-white text-gray-900 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                            step="0.01"
                            min="0"
                          />
                          <span className="ml-1 text-gray-900">€</span>
                        </div>
                        <div className="flex items-center gap-2">
                          {product.hint && (
                            <Tooltip.Root>
                              <Tooltip.Trigger asChild>
                                <button className="text-gray-400 hover:text-gray-600">
                                  <HelpCircle className="h-5 w-5" />
                                </button>
                              </Tooltip.Trigger>
                              <Tooltip.Portal>
                                <Tooltip.Content
                                  className="bg-gray-900 text-white px-4 py-2 rounded-md text-sm max-w-xs"
                                  sideOffset={5}
                                >
                                  {product.hint}
                                  <Tooltip.Arrow className="fill-gray-900" />
                                </Tooltip.Content>
                              </Tooltip.Portal>
                            </Tooltip.Root>
                          )}
                          <div className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm">
                            {product.period}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </Tooltip.Provider>
  );
};
