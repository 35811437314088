import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { Customer, Product } from '../types/notion';

// Definiere die Reihenfolge der Kategorien
const categoryOrder = ['Plan', 'Paid Ads', 'Newsletter', 'Content', 'Social Media', 'weitere Services'];

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: 'Helvetica',
  },
  header: {
    marginBottom: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerLeft: {
    flex: 1,
  },
  headerRight: {
    flex: 1,
    alignItems: 'flex-end',
  },
  logo: {
    width: 50,
    height: 50,
    marginBottom: 10,
  },
  companyInfo: {
    fontSize: 10,
    color: '#666',
    marginBottom: 3,
  },
  title: {
    fontSize: 24,
    marginBottom: 5,
    fontWeight: 'bold',
  },
  date: {
    fontSize: 12,
    color: '#666',
  },
  customerInfo: {
    fontSize: 10,
    marginBottom: 3,
  },
  section: {
    marginBottom: 10,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  productRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
    alignItems: 'flex-start',
  },
  productLeft: {
    flex: 1,
  },
  productRight: {
    width: 150,
    alignItems: 'flex-end',
  },
  priceSection: {
    alignItems: 'flex-end',
    marginBottom: 4,
  },
  periodBadge: {
    backgroundColor: '#e8f0fe',
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4,
  },
  periodBadgeText: {
    color: '#1a73e8',
    fontSize: 12,
  },
  productName: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 2,
  },
  productDescription: {
    fontSize: 12,
    color: '#666',
    marginBottom: 2,
  },
  productNote: {
    fontSize: 12,
    color: '#666',
    marginTop: 2,
    fontStyle: 'italic',
  },
  price: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  period: {
    fontSize: 12,
    color: '#666',
    marginTop: 2,
  },
  totalSummary: {
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#f9fafb',
    padding: 15,
  },
  periodTotal: {
    alignItems: 'flex-end',
  },
  periodLabel: {
    fontSize: 12,
    color: '#666',
    marginBottom: 2,
  },
  periodAmount: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 30,
    right: 30,
    fontSize: 8,
    color: '#666',
    textAlign: 'center',
  },
});

interface QuotePDFProps {
  customer: Customer;
  selectedProducts: Product[];
  totalPrice: number;
  date: string;
  productNotes?: Record<string, string>;
  customPrices?: Record<string, number>;
}

const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount);
};

const ProductItem = ({ product }: { product: Product }) => {
  const price = product.customPrice ?? product.price;
  const period = product.period || 'einmalig';
  
  return (
    <View style={styles.productRow}>
      <View style={styles.productLeft}>
        <Text style={styles.productName}>
          {product.name ? product.name : ' '}
        </Text>
        {product.description ? (
          <Text style={styles.productDescription}>
            {product.description}
          </Text>
        ) : null}
        {product.note && (
          <Text style={styles.productNote}>
            {product.note}
          </Text>
        )}
      </View>
      <View style={styles.productRight}>
        <View style={styles.priceSection}>
          <Text style={styles.price}>
            {formatCurrency(price)}
          </Text>
        </View>
        <View style={styles.periodBadge}>
          <Text style={styles.periodBadgeText}>
            {period}
          </Text>
        </View>
      </View>
    </View>
  );
};

const ProductList = ({ products, category }: { products: Product[]; category: string }) => {
  const categoryProducts = products.filter(p => {
    if (category === 'weitere Services') {
      // Für "weitere Services" zeigen wir Produkte ohne Kategorie, mit dieser Kategorie oder individuelle Produkte
      return !p.category || p.category === category || p.category === 'Individuell';
    }
    return p.category === category;
  });
  
  if (!categoryProducts.length) return null;

  return (
    <View style={styles.section}>
      <Text style={styles.sectionTitle}>
        {category}
      </Text>
      {categoryProducts.map((product, index) => (
        <ProductItem key={index} product={product} />
      ))}
    </View>
  );
};

export const QuotePDF: React.FC<QuotePDFProps> = ({
  customer,
  selectedProducts,
  totalPrice,
  date,
  productNotes,
  customPrices,
}) => {
  const customerAddress = [customer.plz, customer.ort]
    .filter(Boolean)
    .join(' ');

  // Calculate totals by period
  const periodTotals = selectedProducts.reduce((acc, product) => {
    const period = product.period || 'einmalig';
    const price = product.customPrice ?? product.price;
    acc[period] = (acc[period] || 0) + price;
    return acc;
  }, {} as Record<string, number>);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            <Image
              src="https://static.wixstatic.com/media/90de5d_087fb9d65ec14e84bbd4c9bcc0975c58~mv2.png"
              style={styles.logo}
            />
            <Text style={styles.companyInfo}>
              Nordsteg OnlineMarketing eU
            </Text>
            <Text style={styles.companyInfo}>
              Nikolaigasse 22
            </Text>
            <Text style={styles.companyInfo}>
              A-9500 Villach
            </Text>
          </View>
          <View style={styles.headerRight}>
            <Text style={styles.title}>
              Angebot
            </Text>
            <Text style={styles.date}>
              {date || ' '}
            </Text>
          </View>
        </View>

        <View style={styles.customerInfo}>
          {customer.firma ? (
            <Text style={styles.customerInfo}>
              {customer.firma}
            </Text>
          ) : null}
          {customer.ansprechpartner ? (
            <Text style={styles.customerInfo}>
              {customer.ansprechpartner}
            </Text>
          ) : null}
          {customer.strasse ? (
            <Text style={styles.customerInfo}>
              {customer.strasse}
            </Text>
          ) : null}
          {customerAddress ? (
            <Text style={styles.customerInfo}>
              {customerAddress}
            </Text>
          ) : null}
        </View>

        {categoryOrder.map(category => (
          <ProductList 
            key={category} 
            products={selectedProducts} 
            category={category} 
          />
        ))}

        <View style={styles.totalSummary}>
          {['einmalig', 'quartalsweise', 'monatlich'].map(period => (
            periodTotals[period] ? (
              <View key={period} style={styles.periodTotal}>
                <Text style={styles.periodLabel}>
                  {period.charAt(0).toUpperCase() + period.slice(1)}
                </Text>
                <Text style={styles.periodAmount}>
                  {formatCurrency(periodTotals[period])}
                </Text>
              </View>
            ) : null
          ))}
        </View>

        <Text style={styles.footer}>
          Alle Preise verstehen sich zzgl. der gesetzlichen Mehrwertsteuer.
        </Text>
      </Page>
    </Document>
  );
};
