import React from 'react';
import { Product } from '../types/notion';
import { SelectedProducts } from '../hooks/useProducts';

interface TotalCostsProps {
  products: Product[];
  selectedProducts: SelectedProducts;
  productPrices: Record<string, number>;
}

export const TotalCosts: React.FC<TotalCostsProps> = ({ products, selectedProducts, productPrices }) => {
  const totals = products
    .filter(product => selectedProducts[product.id])
    .reduce((acc, product) => {
      const price = productPrices[product.id] ?? product.price;
      const period = product.period || 'einmalig';
      acc[period] = (acc[period] || 0) + price;
      return acc;
    }, {} as Record<string, number>);

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    }).format(amount);
  };

  const periodOrder = ['einmalig', 'monatlich', 'quartalsweise', 'jährlich'];
  const periodLabels: Record<string, string> = {
    einmalig: 'Einmalig',
    monatlich: 'Pro Monat',
    quartalsweise: 'Pro Quartal',
    jährlich: 'Pro Jahr'
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-lg font-semibold text-gray-900 mb-4">Gesamtkosten</h2>
      <div className="space-y-3">
        {periodOrder.map(period => {
          if (!totals[period]) return null;
          return (
            <div key={period} className="flex justify-between items-center">
              <span className="text-gray-600">{periodLabels[period]}</span>
              <span className="font-medium text-gray-900">{formatCurrency(totals[period])}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
